<template>
  <div>
    <div v-if="type == 101">
      <div class="compont_p flex-r">
        收货地址
        <div class="btn flex_c_c" @click="changeType(102)">添加地址</div>
      </div>
      <!-- <div class="form_wrap">
        <el-form label-width="100px" :model="form">
          <el-form-item label="姓名：">
            <el-input v-model="form.name" disabled placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="收货地址：">
            <el-input v-model="form.address_str" disabled placeholder="请输入收货地址"></el-input>
          </el-form-item>
          <el-form-item label="手机号码：">
            <el-input v-model="form.phone" disabled placeholder="请输入手机号码"></el-input>
          </el-form-item>
        </el-form>
      </div> -->
      <div>
        <el-table :data="tableData" class="mytable">
          <el-table-column prop="name" label="收货人" align="center" />
          <el-table-column prop="phone" label="联系电话" align="center" />
          <el-table-column prop="address_str" label="收货地址" align="center" />
          <el-table-column prop="is_default" label="是否默认" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.is_default == 101">是</span>
              <span v-if="scope.row.is_default == 102">否</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleClick(scope.row)">修改</el-button>
              <el-button v-if="scope.row.id != 1" size="mini" type="danger" @click="handleDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <p class="tips">*注：按国家GSP规定，收货地址需和药品经营许可证上地址一致，如需变更请联系专属客服！</p>
    </div>
    <div v-if="type == 102">
      <div class="compont_p flex-r">
        新增地址
        <div class="btn flex_c_c btn1" @click="changeType(101)">返回上级</div>
      </div>
      <div class="form_wrap">
        <el-form label-width="100px" :model="addForm" ref="addForm" :rules="formRules">
          <el-form-item label="姓名：" prop="name">
            <el-input v-model="addForm.name" :disabled="disabled" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号码：" prop="phone">
            <el-input v-model="addForm.phone" :disabled="disabled" placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item label="省市区：" prop="a_id">
            <!-- <el-input v-model="addForm.address_str" :disabled="disabled" placeholder="请输入收货地址"></el-input> -->
            <el-cascader style="width: 500px" v-model="cityValue" ref="citylist" :disabled="disabled" :options="cityData" :props="propsData" @change="handleChange"></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址：" prop="detail">
            <el-input v-model="addForm.detail" :disabled="disabled" placeholder="请输入收货地址"></el-input>
          </el-form-item>

          <el-form-item label="默认地址：">
            <el-switch v-model="defaultAdd" @change="changeDefault" active-color="#13ce66" inactive-color="#DCDFE6"> </el-switch>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="saveAddressFn('addForm')">确 定</el-button>
          </el-form-item>
        </el-form>
        <p class="tips" v-if="disabled">*注：建档地址不可以修改和删除，只能设为默认地址</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: 101,
      tableData: [],
      disabled: false,
      defaultAdd: false,
      cityData: [],
      cityValue: [],
      propsData: {
        value: "value",
        label: "text",
      },
      form: {
        name: "",
        address_str: "",
        phone: "",
      },
      pathLabels: "",
      addForm: {
        id: "",
        name: "",
        address_str: "",
        detail: "",
        phone: "",
        p_id: "",
        c_id: "",
        a_id: "",
      },
      formRules: {
        name: [{ required: true, message: "请输入收货人姓名", trigger: "change" }],
        phone: [{ required: true, message: "请输入收货人联系电话", trigger: "change" }],
        a_id: [{ required: true, message: "请选择省市区", trigger: "change" }],
        detail: [{ required: true, message: "请填写详细地址", trigger: "change" }],
      },
    };
  },
  created() {
    this.$api("account.getCity", {}).then((res) => {
      this.cityData = res.data;
    });
    this.$api("account.getAddress", { id: 1 }).then((res) => {
      this.form = res.data;
    });
    this.getAddress();
  },
  methods: {
    getAddress() {
      this.$api("cart.getAddress", {}).then((res) => {
        this.tableData = res.data;
      });
    },
    changeType(num) {
      this.type = num;
      if (num == 102) {
        this.addForm.id = "";
        this.addForm.name = "";
        this.addForm.phone = "";
        this.addForm.address_str = "";
        this.addForm.detail = "";
        this.addForm.p_id = "";
        this.addForm.c_id = "";
        this.addForm.a_id = "";
        this.cityValue = [];
        this.defaultAdd = false;
        this.disabled = false;
      }
    },
    handleClick(data) {
      this.type = 102;
      this.addForm = data;
      this.addForm.p_id = data.p_id;
      this.addForm.c_id = data.c_id;
      this.addForm.a_id = data.a_id;
      this.cityValue = [data.p_id, data.c_id, data.a_id];
      if (data.is_default == 101) {
        this.defaultAdd = true;
      } else {
        this.defaultAdd = false;
      }
      if (data.id == 1) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    handleDel(data) {
      this.$api("account.delAddress", { id: data.id }).then((res) => {
        console.log(res);
        this.getAddress();
      });
    },
    handleChange(value) {
      this.addForm.p_id = value[0];
      this.addForm.c_id = value[1];
      this.addForm.a_id = value[2];
      let nodesInfo = this.$refs["citylist"].getCheckedNodes();
      this.pathLabels = nodesInfo[0].pathLabels[0] + nodesInfo[0].pathLabels[1] + nodesInfo[0].pathLabels[2];
    },
    changeDefault(e) {
      if (e) {
        this.addForm.is_default = 101;
      } else {
        this.addForm.is_default = 102;
      }
    },
    saveAddressFn(formName) {
      this.addForm.address_str = this.pathLabels + this.addForm.detail;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api("account.saveAddress", this.addForm).then((res) => {
            console.log(res);
            this.type = 101;
            this.getAddress();
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.compont_p {
  position: relative;
  .btn {
    position: absolute;
    left: 110px;
    top: 0;
    width: 76px;
    height: 30px;
    background: #ff4c4c;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
  .btn1 {
    background: @themeColor;
  }
}
.check {
  cursor: pointer;
  width: 48px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: @themeColor;
  border-radius: 4px;
  color: #ffffff;
  .margin-right10 {
    // margin-right: 10rpx;
  }
}
.el-input {
  width: 500px;
}
.form_wrap {
  margin-left: 20px;
  margin-top: 20px;
}
.tips {
  color: @priceRed;
  margin-top: 10px;
  text-align: center;
}
.flex-r {
  display: flex;
  flex-direction: row;
}
.add-btn {
  margin-left: 110px;
}
.mytable {
  .cell {
    display: flex;
    justify-content: center;
  }
}
</style>
